import { render, staticRenderFns } from "./BatteriesGrid.vue?vue&type=template&id=07b1989c"
import script from "./BatteriesGrid.vue?vue&type=script&lang=js"
export * from "./BatteriesGrid.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.98.0__lodash@4._babb32925cd4b8a8c1892c895ee75fdf/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports